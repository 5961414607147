<template>
  <v-container
    :style="
      $vuetify.breakpoint.xs
        ? 'height: calc(100vh - 64px); margin-top: 64px'
        : 'height: calc(100vh - 128px); margin-top: 128px'
    "
    id="crea-intervento"
  >
    <v-row>
      <v-col cols="12">
        <v-card>
          <!-- <v-card-title>
            <span class="text-h5">Crea nuovo intervento</span>
          </v-card-title> -->

          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-container>
                <v-row>
                  <v-col cols="12" sm="3">
                    <v-select
                      :items="tipologie"
                      item-text="nome"
                      item-value="Id"
                      label="Tipo intervento"
                      v-model="nuovoIntervento.tipo"
                      :rules="[(v) => !!v || 'Campo obbligatorio']"
                      @change="resetInput()"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    v-if="
                      nuovoIntervento.tipo != 7 && nuovoIntervento.tipo != 8
                    "
                  >
                    <v-select
                      :items="clienti"
                      item-text="text"
                      item-value="id"
                      label="Cliente"
                      v-model="nuovoIntervento.cliente"
                      :rules="[(v) => !!v || 'Campo obbligatorio']"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="3">
                    <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="nuovoIntervento.data_inizio"
                          label="Inizio intervento"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          :rules="[(v) => !!v || 'Campo obbligatorio']"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="nuovoIntervento.data_inizio"
                        @input="menu1 = false"
                        :min="
                          nuovoIntervento.data_termine &&
                          nuovoIntervento.ricorrenze != 2 &&
                          nuovoIntervento.ricorrenze != 3
                            ? nuovoIntervento.data_termine
                            : currentDate
                        "
                        :max="
                          nuovoIntervento.data_termine
                            ? nuovoIntervento.data_termine
                            : ''
                        "
                        locale="it-IT"
                        first-day-of-week="1"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="nuovoIntervento.ora_inizio"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="nuovoIntervento.ora_inizio"
                          label="Ora inizio"
                          prepend-icon="mdi-clock-time-four-outline"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          :rules="[(v) => !!v || 'Campo obbligatorio']"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menu2"
                        v-model="nuovoIntervento.ora_inizio"
                        full-width
                        @click:minute="
                          $refs.menu2.save(nuovoIntervento.ora_inizio)
                        "
                        :max="nuovoIntervento.ora_termine"
                        format="24hr"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row
                  v-if="nuovoIntervento.tipo != 7 && nuovoIntervento.tipo != 8"
                >
                  <v-col cols="12" sm="3">
                    <v-select
                      :items="ricorrenze"
                      item-text="text"
                      item-value="value"
                      label="Ricorrenze"
                      v-model="nuovoIntervento.ricorrenze"
                      @change="nuovoIntervento.giorni = []"
                      :rules="
                        nuovoIntervento.tipo != 7 && nuovoIntervento.tipo != 8
                          ? [(v) => !!v || 'Campo obbligatorio']
                          : []
                      "
                    >
                    </v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="9"
                    v-if="
                      nuovoIntervento.ricorrenze == 2 ||
                      nuovoIntervento.ricorrenze == 3
                    "
                  >
                    <v-card-text>
                      <span class="subheading">Si ripete il</span>

                      <v-chip-group
                        v-model="nuovoIntervento.giorni"
                        active-class="deep-purple--text text--accent-4"
                        mandatory
                        :multiple="
                          nuovoIntervento.ricorrenze == 3 ? true : false
                        "
                      >
                        <v-chip
                          v-for="(giorno, i) in giorni"
                          :key="i"
                          :value="giorno.value"
                        >
                          {{ giorno.text }}
                        </v-chip>
                      </v-chip-group>
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="3">
                    <v-menu
                      v-model="menu3"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="nuovoIntervento.data_termine"
                          label="Fine intervento"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          :clearable="true"
                          :rules="
                            !nuovoIntervento.ora_termine &&
                            nuovoIntervento.tipo != 7 &&
                            nuovoIntervento.tipo != 8 &&
                            nuovoIntervento.ricorrenze != 2 &&
                            nuovoIntervento.ricorrenze != 3
                              ? []
                              : [(v) => !!v || 'Campo obbligatorio']
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="nuovoIntervento.data_termine"
                        @input="menu3 = false"
                        :min="nuovoIntervento.data_inizio"
                        :max="
                          nuovoIntervento.tipo != 7 &&
                          nuovoIntervento.tipo != 8 &&
                          nuovoIntervento.ricorrenze != 2 &&
                          nuovoIntervento.ricorrenze != 3
                            ? nuovoIntervento.data_inizio
                            : ''
                        "
                        locale="it-IT"
                        first-day-of-week="1"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="6" sm="3">
                    <v-menu
                      ref="menu4"
                      v-model="menu4"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="nuovoIntervento.ora_termine"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="nuovoIntervento.ora_termine"
                          label="Ora fine"
                          prepend-icon="mdi-clock-time-four-outline"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          :clearable="true"
                          :rules="
                            !nuovoIntervento.data_termine
                              ? []
                              : [(v) => !!v || 'Campo obbligatorio']
                          "
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menu4"
                        v-model="nuovoIntervento.ora_termine"
                        full-width
                        format="24hr"
                        @click:minute="
                          $refs.menu4.save(nuovoIntervento.ora_termine)
                        "
                        :min="
                          nuovoIntervento.data_inizio ==
                            nuovoIntervento.data_termine ||
                          nuovoIntervento.ricorrenze == 2 ||
                          nuovoIntervento.ricorrenze == 3 ||
                          nuovoIntervento.ora_inizio
                            ? nuovoIntervento.ora_inizio
                            : ''
                        "
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row
                  v-for="(utente, i) in nuovoIntervento.dipendenti"
                  :key="i"
                >
                  <v-col cols="12" sm="3">
                    <v-select
                      :items="utentiNomi"
                      item-text="nome"
                      item-value="id"
                      label="Dipendente"
                      v-model="nuovoIntervento.dipendenti[i].id_utente"
                      :rules="
                        nuovoIntervento.tipo != 7 && nuovoIntervento.tipo != 8
                          ? [
                              checkSelection(
                                nuovoIntervento.dipendenti[i].id_utente,
                                i
                              ) || 'Dipendete è stato selezionato più volte',
                            ]
                          : [(v) => !!v || 'Campo obbligatorio']
                      "
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="3"
                    v-if="
                      nuovoIntervento.tipo != 7 && nuovoIntervento.tipo != 8
                    "
                  >
                    <v-select
                      :items="categorie"
                      item-text="categoria"
                      item-value="Id"
                      label="Categoria"
                      v-model="nuovoIntervento.dipendenti[i].id_categoria"
                      :disabled="!nuovoIntervento.dipendenti[i].id_utente"
                      :rules="
                        !nuovoIntervento.dipendenti[i].id_utente
                          ? []
                          : [
                              (v) =>
                                !!v || 'Seleziona una categoria per dipendente',
                            ]
                      "
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="3"
                    class="d-flex align-center"
                    v-if="
                      nuovoIntervento.tipo != 7 && nuovoIntervento.tipo != 8
                    "
                  >
                    <v-btn
                      class="ma-2"
                      x-small
                      outlined
                      fab
                      color="primary"
                      @click="removeUtente(i)"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row
                  v-if="nuovoIntervento.tipo != 7 && nuovoIntervento.tipo != 8"
                >
                  <v-col cols="12" sm="4">
                    <v-btn small color="primary" dark @click="aggiungiUtente()">
                      Aggiungi dipendente
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-textarea
                      :clearable="true"
                      outlined
                      clear-icon="mdi-close-circle"
                      label="Note"
                      v-model="nuovoIntervento.note"
                      maxlength="255"
                      counter="255"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="resetForm()">
              Cancella tutto
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :disabled="!valid"
              :loading="buttonLoading"
              @click="saveIntervento()"
            >
              Salva
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <!-- ALERT DI ERRORE DATA/ORA -->
      <v-dialog v-model="errorDialog" max-width="500px">
        <v-alert outlined type="error" elevation="2" class="mb-0">
          {{ dateError }}
        </v-alert>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CreaIntervento",
  data() {
    return {
      clienti: [],
      currentDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      nuovoIntervento: {
        cliente: "",
        tipo: "",
        data_inizio: "",
        ora_inizio: "",
        data_termine: "",
        ora_termine: "",
        dipendenti: [{ id_utente: "", id_categoria: "" }],
        note: "",
        ricorrenze: "",
        giorni: [],
      },
      tipologie: [],
      categorie: [],
      buttonLoading: false,
      valid: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      utentiNomi: [],
      dateError: "",
      errorDialog: false,
      ricorrenze: [
        { text: "Non si ripete", value: 1 },
        { text: "Una volta a settimana", value: 2 },
        { text: "Più volte a settimana", value: 3 },
      ],
      giorni: [
        { text: "L", value: 1 },
        { text: "M", value: 2 },
        { text: "M", value: 3 },
        { text: "G", value: 4 },
        { text: "V", value: 5 },
        { text: "S", value: 6 },
        { text: "D", value: 0 },
      ],
    };
  },
  mounted() {
    this.$store.state.loading = true;
    this.tipologie = JSON.parse(localStorage.getItem("tipologie"));
    this.categorie = JSON.parse(localStorage.getItem("categorie"));
    this.categorie.forEach((el, i) => {
      if (el.categoria == "Imprevisto") {
        this.categorie.splice(i, 1);
      }
    });
    this.getClientiUtenti();
  },
  methods: {
    getClientiUtenti() {
      this.axios
        .get(`${localStorage.getItem("url")}utenti`, {
          params: {
            id_utente: localStorage.getItem("user"),
            nuovo_int: true,
          },
        })
        .then((response) => {
          this.clienti = response.data.clienti;
          this.utenti = response.data.utenti;
          this.utenti.forEach((el) => {
            var utente = {
              nome: `${el.nome} ${el.cognome}`,
              id: el.id,
            };
            this.utentiNomi.push(utente);
          });
          this.$store.state.loading = false;
        })
        .catch((error) => {
          this.$store.state.loading = false;
          if (error.response != undefined) {
            if (error.response.status == 401) {
              this.$root.$emit("logout", error.response.data.message);
            }
          }
        });
    },
    aggiungiUtente() {
      this.nuovoIntervento.dipendenti.push({
        id_utente: "",
        id_categoria: "",
      });
    },
    removeUtente(i) {
      if (this.nuovoIntervento.dipendenti != undefined) {
        if (this.nuovoIntervento.dipendenti.length > 1) {
          this.nuovoIntervento.dipendenti.splice(i, 1);
        } else {
          this.nuovoIntervento.dipendenti = [
            {
              id_utente: "",
              id_categoria: "",
            },
          ];
        }
      }
    },
    checkSelection(utente, index) {
      if (utente != "") {
        var found = this.nuovoIntervento.dipendenti.some(
          (el, i) => el.id_utente == utente && i != index
        );
        if (found) return false;
        return true;
      }
      return true;
    },
    saveIntervento() {
      this.buttonLoading = true;
      this.dateError = "";

      // CONTROLLO IL VALORE DELLE DATE/ORE
      if (
        this.nuovoIntervento.data_inizio == null ||
        this.nuovoIntervento.data_inizio == undefined
      ) {
        this.nuovoIntervento.data_inizio = "";
      }
      if (
        this.nuovoIntervento.data_termine == null ||
        this.nuovoIntervento.data_termine == undefined
      ) {
        this.nuovoIntervento.data_termine = "";
      }

      if (
        this.nuovoIntervento.ora_inizio == null ||
        this.nuovoIntervento.ora_inizio == undefined
      ) {
        this.nuovoIntervento.ora_inizio = "";
      }
      if (
        this.nuovoIntervento.ora_termine == null ||
        this.nuovoIntervento.ora_termine == undefined
      ) {
        this.nuovoIntervento.ora_termine = "";
      }

      // CREO LA STRINGA PER POTER CONFRONTARE INIZIO E TERMINE
      var inizio = this.nuovoIntervento.data_inizio.concat(
        " ",
        this.nuovoIntervento.ora_inizio
      );
      var termine = this.nuovoIntervento.data_termine.concat(
        " ",
        this.nuovoIntervento.ora_termine
      );

      if (new Date(inizio) >= new Date(termine)) {
        this.dateError =
          "Data e ora di inizio deve essere minore della data e ora di fine intervento";
        this.errorDialog = true;
      }

      if (
        this.nuovoIntervento.data_termine != "" &&
        this.nuovoIntervento.tipo != 7 &&
        this.nuovoIntervento.tipo != 8 &&
        this.nuovoIntervento.ricorrenze != 2 &&
        this.nuovoIntervento.ricorrenze != 3 &&
        new Date(this.nuovoIntervento.data_inizio).toLocaleDateString() !=
          new Date(this.nuovoIntervento.data_termine).toLocaleDateString()
      ) {
        this.dateError =
          "Data di fine deve essere uguale alla data di inizio intervento";
        this.errorDialog = true;
      }

      if (
        (this.nuovoIntervento.ricorrenze == 2 ||
          this.nuovoIntervento.ricorrenze == 3) &&
        new Date(this.nuovoIntervento.data_inizio).toLocaleDateString() ==
          new Date(this.nuovoIntervento.data_termine).toLocaleDateString()
      ) {
        this.dateError =
          "Data di fine deve essere maggiore della data di inizio intervento";
        this.errorDialog = true;
      }

      if (
        (this.nuovoIntervento.ricorrenze == 2 ||
          this.nuovoIntervento.ricorrenze == 3) &&
        new Date(this.nuovoIntervento.data_inizio).toLocaleDateString() !=
          new Date(this.nuovoIntervento.data_termine).toLocaleDateString() &&
        this.nuovoIntervento.ora_inizio == this.nuovoIntervento.ora_termine
      ) {
        this.dateError =
          "Ora di fine deve essere maggiore dell'ora di inizio intervento";
        this.errorDialog = true;
      }

      if (
        this.nuovoIntervento.ricorrenze == 2 ||
        this.nuovoIntervento.ricorrenze == 3
      ) {
        var dayOfWeekStart = new Date(
          this.nuovoIntervento.data_inizio
        ).getDay();
        var dayOfWeekEnd = new Date(this.nuovoIntervento.data_termine).getDay();
        if (this.nuovoIntervento.ricorrenze == 3) {
          this.nuovoIntervento.giorni.sort();
        }

        if (
          this.nuovoIntervento.ricorrenze == 2 &&
          this.nuovoIntervento.giorni != dayOfWeekStart
        ) {
          this.dateError =
            "Giorno della settimana selezionato non corrisponde alla data di inizio";
          this.errorDialog = true;
        } else if (
          this.nuovoIntervento.ricorrenze == 2 &&
          this.nuovoIntervento.giorni != dayOfWeekEnd
        ) {
          this.dateError =
            "Giorno della settimana selezionato non corrisponde alla data di fine";
          this.errorDialog = true;
        } else if (
          this.nuovoIntervento.ricorrenze == 3 &&
          !this.nuovoIntervento.giorni.includes(dayOfWeekStart)
        ) {
          this.dateError =
            "Data di inizio non corrisponde a nessuno dei giorni della settimana selezionati";
          this.errorDialog = true;
        } else if (
          this.nuovoIntervento.ricorrenze == 3 &&
          !this.nuovoIntervento.giorni.includes(dayOfWeekEnd)
        ) {
          this.dateError =
            "Data di fine non corrisponde a nessuno dei giorni della settimana selezionati";
          this.errorDialog = true;
        }
      }

      if (!this.errorDialog) {
        if (this.nuovoIntervento.ricorrenze == 2) {
          this.nuovoIntervento.giorni = [this.nuovoIntervento.giorni];
        }
        // CONTROLLO I VALORI DEI DIPENDETI/CATEGORIE E AZZERO CAMPI NON PRESENTI PER TIPO 7/8
        if (this.nuovoIntervento.tipo != 7 && this.nuovoIntervento.tipo != 8) {
          var indexes = [];
          this.nuovoIntervento.dipendenti.forEach((el) => {
            if (
              el.id_utente == null ||
              el.id_utente == "" ||
              el.id_categoria == null ||
              el.id_categoria == ""
            ) {
              var index = this.nuovoIntervento.dipendenti.indexOf(el);
              if (this.nuovoIntervento.dipendenti.length > 1) {
                indexes.push(index);
              } else {
                this.nuovoIntervento.dipendenti = [
                  { id_utente: 0, id_categoria: 0 },
                ];
              }
            }
          });
          indexes = indexes.reverse();
          for (let i = 0; i < indexes.length; i++) {
            this.nuovoIntervento.dipendenti.splice(indexes[i], 1);
          }
        } else {
          this.nuovoIntervento.dipendenti[0].id_categoria = null;
          this.nuovoIntervento.cliente = null;
          this.nuovoIntervento.ricorrenze = 1;
          this.nuovoIntervento.giorni = null;
        }

        this.axios
          .post(`${localStorage.getItem("url")}interventi`, {
            id_utente: localStorage.getItem("user"),
            nuovo_int: true,
            id_cliente: this.nuovoIntervento.cliente,
            dipendenti: this.nuovoIntervento.dipendenti,
            id_tipo: this.nuovoIntervento.tipo,
            data_inizio:
              this.nuovoIntervento.data_inizio == ""
                ? null
                : this.nuovoIntervento.data_inizio,
            ora_inizio:
              this.nuovoIntervento.ora_inizio == ""
                ? null
                : this.nuovoIntervento.ora_inizio,
            data_termine:
              this.nuovoIntervento.data_termine == ""
                ? null
                : this.nuovoIntervento.data_termine,
            ora_termine:
              this.nuovoIntervento.ora_termine == ""
                ? null
                : this.nuovoIntervento.ora_termine,
            note:
              this.nuovoIntervento.note == undefined ||
              this.nuovoIntervento.note == ""
                ? null
                : this.nuovoIntervento.note,
            ricorrenze:
              this.nuovoIntervento.ricorrenze == undefined ||
              this.nuovoIntervento.ricorrenze == ""
                ? null
                : this.nuovoIntervento.ricorrenze,
            giorni:
              this.nuovoIntervento.giorni == undefined ||
              this.nuovoIntervento.giorni == ""
                ? null
                : this.nuovoIntervento.giorni,
            modifica_tutti: false,
            source: "web",
          })
          .then(() => {
            this.buttonLoading = false;
            this.$root.Snackbar.show({
              text: `L'intervento è stato creato`,
            });
            this.resetForm();
          })
          .catch((error) => {
            this.buttonLoading = false;
            this.resetForm();
            if (error.response != undefined) {
              if (error.response.status == 401) {
                this.$root.$emit("logout", error.response.data.message);
              }
            }
          });
      }
      this.buttonLoading = false;
    },
    resetInput() {
      this.nuovoIntervento = {
        cliente: "",
        tipo: this.nuovoIntervento.tipo,
        data_inizio: "",
        ora_inizio: "",
        data_termine: "",
        ora_termine: "",
        dipendenti: [{ id_utente: "", id_categoria: "" }],
        note: "",
        ricorrenze: "",
        giorni: [],
      };
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }
      });
    },
    resetForm() {
      this.nuovoIntervento = {
        cliente: "",
        tipo: "",
        data_inizio: "",
        ora_inizio: "",
        data_termine: "",
        ora_termine: "",
        dipendenti: [{ id_utente: "", id_categoria: "" }],
        note: "",
        ricorrenze: "",
        giorni: [],
      };
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.reset();
        }
      });
    },
  },
};
</script>

<style>
.v-alert--outlined {
  background-color: white !important;
}
</style>