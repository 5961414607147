import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    searchClienti: '',
    filtersClienti: {
      rag_soc: [],
      indirizzo: "",
      CAP: "",
      loc: [],
      prov: [],
      tel: "",
      email: "",
    },
    searchUtenti: "",
    filtersUtenti: {
      nome: "",
      indirizzo: "",
      // CAP: "",
      loc: [],
      // prov: [],
      tel: "",
      email: "",
      clienti: [],
    },
    searchInterventi: "",
    filtersInterventi: {
      id_int: "",
      nome_cognome: [],
      tipologia: [],
      rag_soc: [],
      indirizzo: "",
      categoria: [],
      dates: [],
      gestito: [],
    },
    searchNote: "",
  },
  mutations: {
    deleteAllData(state) {
      state.loading = false;
      state.searchClienti = '';
      state.filtersClienti = {
        rag_soc: [],
        indirizzo: "",
        CAP: "",
        loc: [],
        prov: [],
        tel: "",
        email: "",
      };
      state.searchUtenti = "";
      state.filtersUtenti = {
        nome: "",
        indirizzo: "",
        // CAP: "",
        loc: [],
        // prov: [],
        tel: "",
        email: "",
        clienti: [],
      };
      state.searchInterventi = "";
      state.filtersInterventi = {
        id_int: "",
        nome_cognome: [],
        tipologia: [],
        rag_soc: [],
        indirizzo: "",
        categoria: [],
        dates: [],
        gestito: [],
      };
      state.searchNote = "";
      localStorage.clear();
    }
  },
  actions: {
  },
  modules: {
  }
})
