<template>
  <v-app id="gestionale">
    <v-card class="overflow-hidden" style="border-radius: 0">
      <v-app-bar
        absolute
        dark
        :height="$vuetify.breakpoint.xs ? '64px' : '124px'"
        src="./assets/Immagine-BarraNav-v2.jpg"
        v-if="$route.path != '/' && $route.name != 'PageError'"
        class="navbar"
      >
        <template v-slot:img="{ props }">
          <v-img
            v-bind="props"
            gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
          ></v-img>
        </template>

        <v-app-bar-nav-icon
          v-if="
            $route.path != '/' &&
            $route.path != '/aggiorna-password' &&
            $route.path != '/recupera-password'
          "
          @click="drawer = !drawer"
          :class="
            $vuetify.breakpoint.xs ? 'align-self-center' : 'align-self-start'
          "
        ></v-app-bar-nav-icon>

        <v-toolbar-title
          :class="
            $vuetify.breakpoint.xs ? 'align-self-center' : 'align-self-end pb-2'
          "
          width="100%"
          >{{ pageTitle }}</v-toolbar-title
        >

        <v-spacer></v-spacer>
        <v-btn
          icon
          :class="
            $vuetify.breakpoint.xs ? 'align-self-center' : 'align-self-start'
          "
          @click="$route.path != '/note' ? $router.push('/note') : ''"
          v-if="
            $route.path != '/' &&
            $route.path != '/aggiorna-password' &&
            $route.path != '/recupera-password'
          "
        >
          <v-badge :content="note" :value="note" color="red" overlap>
            <v-icon> mdi-bell </v-icon>
          </v-badge>
        </v-btn>
        <v-btn
          icon
          :class="
            $vuetify.breakpoint.xs ? 'align-self-center' : 'align-self-start'
          "
          @click="logout('')"
          v-if="
            $route.path != '/' &&
            $route.path != '/aggiorna-password' &&
            $route.path != '/recupera-password'
          "
        >
          <v-icon>mdi-export</v-icon>
        </v-btn>
      </v-app-bar>
      <v-navigation-drawer
        v-model="drawer"
        fixed
        temporary
        bottom
        mobile-breakpoint="540"
        style="
          background-image: linear-gradient(
            to top right,
            rgba(19, 84, 122, 0.2),
            rgba(128, 208, 199, 0.5)
          );
        "
      >
        <v-list>
          <v-list-item v-for="(item, i) in menu" :key="i" link :to="item.href">
            <v-list-item-icon>
              <v-icon>{{ item.icona }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.nome }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-sheet
        :class="
          $route.path == '/' ? 'overflow-y-auto bg-color' : 'overflow-y-auto'
        "
      >
        <router-view
          v-if="checkHeader"
          @logout="logout"
          @get-notifiche="getNumeroNote"
        >
        </router-view>
        <Loading v-if="$store.state.loading" /><Snackbar ref="snackbar" />
      </v-sheet>
    </v-card>
  </v-app>
</template>

<script>
import router from "./router";
import Loading from "./components/Loading";
import Snackbar from "./components/Snackbar.vue";

export default {
  name: "App",
  components: {
    Loading,
    Snackbar,
  },
  data: () => ({
    pageTitle: "",
    note: 0,
    drawer: null,
    menu: [
      {
        nome: "Home",
        icona: "mdi-home",
        href: "/home",
      },
      {
        nome: "Clienti",
        icona: "mdi-briefcase",
        href: "/clienti",
      },
      {
        nome: "Dipendenti",
        icona: "mdi-account-multiple",
        href: "/dipendenti",
      },
      {
        nome: "Crea intervento",
        icona: "mdi-plus-circle",
        href: "/crea-intervento",
      },
      {
        nome: "Riepilogo interventi",
        icona: "mdi-database",
        href: "/riepilogo-interventi",
      },
      {
        nome: "Note generali",
        icona: "mdi-note-multiple",
        href: "/note",
      },
    ],
    loading: false,
    refToken: "",
    checkHeader: false,
    refreshStarted: false,
  }),
  created() {
    localStorage.setItem("url", "https://cleanandcheck.newfaemac.it/api/");
    //localStorage.setItem("url", "http://192.168.1.83:5000/api/");
    this.checkLogin();
  },
  mounted() {
    this.$root.Snackbar = this.$refs.snackbar;
    this.$root.$once("logout", this.logout);
  },
  methods: {
    checkLogin() {
      this.checkHeader = false;
      if (
        this.axios.defaults.headers.common["X-Auth-Token"] != null &&
        this.axios.defaults.headers.common["X-Auth-Token"] != undefined &&
        this.axios.defaults.headers.common["X-Auth-Token"] != ""
      ) {
        //Controllo se jwt nell'header è ancora valido
        this.axios
          .get(`${localStorage.getItem("url")}jwt`)
          .then(() => {
            this.checkHeader = true;
            if (this.$route.path == "/") {
              router.push("/home");
            }
          })
          .catch((error) => {
            this.axios.defaults.headers.common["X-Auth-Token"] = "";
            this.checkHeader = true;
            this.logout(error.response.data.message);
          });
      } else {
        //Genero nuovo jwt se la funzione non è già partita e se il jwt nei cookies è valido
        if (
          !this.refreshStarted &&
          this.$route.path != "/aggiorna-password" &&
          this.$route.path != "/recupera-password"
        ) {
          this.getAT();
        } else {
          if (!this.refreshStarted) {
            this.checkHeader = true;
          }
        }
      }
    },
    getNumeroNote() {
      //Scarico il numero di note/notifiche ricevute
      this.axios
        .get(`${localStorage.getItem("url")}notifiche`, {
          params: {
            id_utente: localStorage.getItem("user"),
          },
        })
        .then((response) => {
          this.note = response.data.note;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.axios.defaults.headers.common["X-Auth-Token"] = "";
            this.$store.commit("deleteAllData");
            this.logout(error.response.data.message);
          }
        });
    },
    logout(message) {
      this.axios
        .post(`${localStorage.getItem("url")}refresh/logout`)
        .then(() => {
          this.axios.defaults.headers.common["X-Auth-Token"] = "";
          this.$store.commit("deleteAllData");
          localStorage.setItem(
            "url",
            "https://cleanandcheck.newfaemac.it/api/"
          );
          //localStorage.setItem("url", "http://192.168.1.83:5000/api/");

          if (this.$route.path != "/") {
            router.push("/");
            if (message != "") {
              this.$root.Snackbar.show({
                text: message,
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.axios.defaults.headers.common["X-Auth-Token"] = "";
          this.$store.commit("deleteAllData");
          localStorage.setItem(
            "url",
            "https://cleanandcheck.newfaemac.it/api/"
          );
          //localStorage.setItem("url", "http://192.168.1.83:5000/api/");
          if (this.$route.path != "/") {
            router.push("/");
            if (error.response != undefined) {
              if (error.response.status == 401) {
                if (message != "") {
                  this.$root.Snackbar.show({
                    text: message,
                  });
                }
              }
            }
          }
        });
    },
    getAT() {
      this.$store.state.loading = true;
      if (
        localStorage.getItem("url") != null &&
        localStorage.getItem("url") != undefined &&
        localStorage.getItem("url") != ""
      ) {
        this.checkHeader = false;
        this.refreshStarted = true;
        //Prendo il nuovo access e refresh jwt utilizzando il jwt attuale nei cookies
        this.axios
          .post(`${localStorage.getItem("url")}refresh`)
          .then((response) => {
            this.axios.defaults.headers.common["X-Auth-Token"] =
              response.data.at;
            this.checkHeader = true;
            if (this.$route.path == "/") {
              router.push("/home");
            }
            this.refreshStarted = false;
            this.getNumeroNote();
            this.$store.state.loading = false;
          })
          .catch((error) => {
            this.$store.state.loading = false;
            this.checkHeader = true;
            this.refreshStarted = false;
            this.axios.defaults.headers.common["X-Auth-Token"] = "";
            this.$store.commit("deleteAllData");
            localStorage.setItem(
              "url",
              "https://cleanandcheck.newfaemac.it/api/"
            );
            //localStorage.setItem("url", "http://192.168.1.83:5000/api/");
            if (this.$route.path != "/") {
              router.push("/");

              if (error.response != undefined) {
                if (error.response.status == 401) {
                  if (error.response.data.message != undefined) {
                    this.$root.Snackbar.show({
                      text: error.response.data.message,
                    });
                  }
                }
              }
            }
          });
      } else {
        this.$store.state.loading = false;
        this.checkHeader = true;
      }
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.pageTitle = to.meta.title;
        document.title = to.meta.title;
        if (
          !this.refreshStarted &&
          this.$route.path != "/" &&
          this.$route.path != "/aggiorna-password" &&
          this.$route.path != "/recupera-password"
        ) {
          this.getAT();
        }

        if (this.$route.path == "/") {
          this.checkLogin();
        }
      },
    },
  },
};
</script>

<style>
.bg-color {
  background-color: #a4c4b5 !important;
}

#gestionale
  > div
  > div
  > header.navbar
  > div.v-toolbar__image
  > div
  > div.v-image__image.v-image__image--cover {
  background-size: contain;
  background-repeat: repeat-x;
}

@media only screen and (max-width: 600px) {
  #gestionale .v-data-footer {
    position: fixed;
    bottom: 0;
    background-color: white;
  }
}

@media only screen and (max-width: 1044px) {
  #gestionale
    > div
    > div
    > header.navbar
    > div.v-toolbar__image
    > div
    > div.v-image__image.v-image__image--cover {
    background-size: cover;
    background-repeat: repeat-x;
  }
}
</style>