<template>
  <v-container
    id="recupera"
    :style="
      $vuetify.breakpoint.xs
        ? 'height: calc(100vh - 64px); margin-top: 64px'
        : 'height: calc(100vh - 128px); margin-top: 128px'
    "
  >
    <v-card
      :width="$vuetify.breakpoint.xs ? '100%' : '50%'"
      class="pa-5 mx-auto"
    >
      <v-form ref="form" @submit.prevent="conferma()" v-model="valid">
        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          required
          autocomplete="email"
        ></v-text-field>
        <v-text-field
          v-model="pswd1"
          label="Nuova password"
          required
          autocomplete="new-password"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show1 ? 'text' : 'password'"
          @click:append="show1 = !show1"
          :rules="[
            (v) => !!v || 'Campo obbligatorio',
            (v) =>
              v.length >= 6 ||
              'La password deve essere lunga almeno 6 caratteri!',
          ]"
        ></v-text-field>
        <v-text-field
          v-model="pswd2"
          label="Ripeti password"
          required
          autocomplete="new-password"
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show2 ? 'text' : 'password'"
          @click:append="show2 = !show2"
          :rules="[(v) => !!v || 'Campo obbligatorio']"
        ></v-text-field>
        <div v-if="!validatePswd && pswd1 && pswd2">
          <span style="color: red">Le due password non corrispondono!</span>
        </div>

        <v-btn
          color="primary"
          class="mt-2"
          :disabled="validatePswd && valid ? false : true"
          type="submit"
          rounded
          :loading="buttonLoading"
        >
          Conferma
        </v-btn>
      </v-form></v-card
    >
  </v-container>
</template>

<script>
export default {
  name: "Recupera",
  data: () => ({
    valid: false,
    buttonLoading: false,
    email: "",
    pswd1: "",
    pswd2: "",
    show1: false,
    show2: false,
    message: "",
    emailRules: [
      (v) => !!v || "Campo obbligatorio",
      (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
    ],
    validatePswd: false,
  }),
  mounted() {
    this.id = localStorage.getItem("user");
  },
  methods: {
    validate() {
      if (this.pswd1 == this.pswd2) {
        this.validatePswd = true;
      } else {
        this.validatePswd = false;
      }
    },
    conferma() {
      this.buttonLoading = true;
      this.axios
        .post(`${localStorage.getItem("url")}recupera`, {
          email: this.email,
          password: this.pswd2,
        })
        .then(() => {
          this.buttonLoading = false;
          this.$store.state.loading = false;
          this.$router.push("/");
          this.$root.Snackbar.show({
            text: "Password modificata",
          });
        })
        .catch((error) => {
          this.buttonLoading = false;
          this.$root.Snackbar.show({
            text: error.response.data.message,
          });
        });
    },
  },
  watch: {
    pswd1: {
      handler() {
        this.validate();
      },
      immediate: true,
    },
    pswd2: {
      handler() {
        this.validate();
      },
      immediate: true,
    },
  },
};
</script>